import React from "react";
import cn from "classnames";

import "./ServiceItem.scss";

interface ServiceItemProps {
  title: string;
  icon?: React.ReactNode;
  className?: string;
  onlyText?: boolean;
  onClick?: () => void;
  href?: string; // Optional prop for links
}

export const ServiceItem = ({
  title,
  icon,
  className,
  onClick,
  onlyText,
  href,
}: ServiceItemProps) => {
  const Wrapper = href ? "a" : "div"; // Use <a> if href is provided, otherwise <div>

  return (
    <Wrapper
      onClick={!href ? onClick : undefined} // Only use onClick if no href
      href={href} // Add href for <a> tag
      className={cn(className, "service-item")}
      {...(href && { target: "_self" })} // Ensure default target is self
    >
      {!onlyText && <div className="service-item__icon">{icon}</div>}
      <div className="service-item__title">{title}</div>
    </Wrapper>
  );
};
